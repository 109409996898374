import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Invoice = _resolveComponent("Invoice")

  return (_openBlock(), _createBlock(_component_Invoice, {
    type: $setup.invoiceType.Debit,
    invoiceId: $props.invoiceId,
    orderId: $props.orderId,
    editMode: true
  }, null, 8, ["type", "invoiceId", "orderId"]))
}